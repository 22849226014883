<template>
  <div>
    个人中心
    <div>
      <el-avatar :src="UserInfo.avatar"> </el-avatar>
      <span>{{ UserInfo.name }}</span>
      <span>{{ UserInfo.email }}</span>
    </div>
  </div>
</template>

<script>
// import { reactive } from "vue";
export default {
  name: "Personal",
  components: {},
  setup() {
    let UserInfo = JSON.parse(localStorage.getItem("userInfo"));
    return {
      UserInfo,
    };
  },
};
</script>

<style>
</style>
